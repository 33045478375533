// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Data = {
  pageName: 'HtmlLightBox Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: '#54a8d1',
        },
        componentChildren: [
          {
            componentName: 'HtmlRender',
            componentData: {
              html: '<h2 class="text-center">3 Columns</h2>',
            },
          },
          {
            componentName: 'Columns',
            componentData: {
              columns: 3,
              gap: '40',
            },
            componentChildren: [
              {
                componentName: 'HtmlRender',
                componentData: {
                  html: '<h2 class="text-center">Columns #1</h2>',
                },
              },
              {
                componentName: 'HtmlRender',
                componentData: {
                  html: '<h2 class="text-center">Column #2</h2>',
                },
              },
              {
                componentName: 'HtmlRender',
                componentData: {
                  html: '<h2 class="text-center">Column #2</h2>',
                },
              },
            ],
          },
        ],
      },
    ],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'HtmlRender',
      componentData: {
        html: '<h2 class="text-center">2 Columns</h2>',
      },
    },
    {
      componentName: 'Columns',
      componentData: {
        columns: 2,
        gap: '40',
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html: '<h2 class="text-center">Columns #1</h2>',
          },
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2 class="text-center"><a href="#tester">Column #2</a></h2>',
          },
        },
      ],
    },
    {
      componentName: 'HtmlRender',
      componentData: {
        html: '<h2 class="text-center">HTML render test</h2>',
      },
    },
    {
      componentName: 'LightBox',
      componentData: {
        hashtag: 'tester',
        content:
          '<h2>a b c d e f g h i m j k l m n o p q r s t u v w x y z æ ø å a b c d e f g h i m j k l m n o p q r s t u v w x y z æ ø å a b c d e f g h i m j k l m n o p q r s t u v w x y z æ ø å a b c d e f g h i m j k l m n o p q r s t u v w x y z æ ø å</h2><p>text</p>',
      },
    },
  ],
};

export default Data;
